import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/RuthinSchool",
    name: "RuthinSchool",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RuthinSchool.vue"),
  },
  {
    path: "/ArtFestival",
    name: "ArtFestival",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ArtFestival.vue"),
  },
  // {
  //   path: "/Contact",
  //   name: "Contact",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Contact.vue"),
  // },
  {
    path: "/ShortCoursesCamps",
    name: "ShortCoursesCamps",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ShortCoursesCamps.vue"),
  },
  {
    path: "/TalentAward",
    name: "TalentAward",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TalentAward.vue"),
  },
  {
    path: "/Registration",
    name: "Registration",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Registration.vue"),
  },
  {
    path: "/sumbit",
    name: "sumbit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/sumbit.vue"),
  },
  {
    path: "/Submissionguidance",
    name: "Submissionguidance",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Submissionguidance.vue"),
  },
  {
    path: "/UploadDetails",
    name: "UploadDetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UploadDetails.vue"),
  },
  {
    path: "/ChangePassword",
    name: "ChangePassword",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ChangePassword.vue"),
  },
  {
    path: "/SubmitWork",
    name: "SubmitWork",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SubmitWork.vue"),
  },
  {
    path: "/Terms",
    name: "Terms",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Terms.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login.vue"),
  },
  {
    path: "/Forgot",
    name: "Forgot",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Forgot.vue"),
  },
  {
    path: "/forgetpsd",
    name: "forgetpsd",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/forgetpsd.vue"),
  },
  {
    path: "/artlist",
    name: "artlist",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artlist.vue"),
  },
];

const router = new VueRouter({
  // mode: "history", // 去掉url中的#
  routes,
});
const VueRouterPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
export default router;
