// zn.js
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
export default {
  ...zhLocale,
  headmenu: {
    msg1: "首页",
    msg2: "关于我们",
    msg3: "露丝公学",
    msg4: "露丝艺术教育",
    msg5: "短期国际教育项目",
    msg6: "岳敏君青少年艺术奖",
    msg7: "中文/English",
    msg8: "露丝教育",
    msg9: "",
    msg10: "国际艺术节",
  },
  footpage: {
    msg1: `露丝教育`,
    msg2: `网站使用条款`,
    msg3: `微信公众号`,
    msg4: `推特`,
    msg5: `领英`,
    msg6: `Instagram`,
    msg7: `地址`,
    msg8: `加入社区`,
    msg9: `加入艺术教育社区`,
    msg10: `露丝国际艺术节`,
    msg11: `抖音`,
    msg12: `小红书`,
    msg13: `岳敏君青少年艺术奖`,
    msg14: `加入社区`,
  },
  ArtFestival: {
    msg1: `岳敏君青少年艺术奖`,
    msg2: `岳敏君青少年艺术奖是一项国际视觉艺术奖项，旨在表彰杰出青少年艺术家的创造力，以支持他们未来的艺术旅程和职业生涯`,
    msg3: `更多`,
    msg4: `英国露丝国际艺术节`,
    msg5: `拓展艺术的界限，探索无限可能性——露丝国际艺术节为全球青年艺术家和前卫实验性艺术项目提供了一个引人注目的舞台。该艺术节涵盖三个主要领域：当代艺术、电影和诗歌。展示形式包括行为艺术、影像艺术、装置艺术、绘画、声音艺术以及朗诵等多种形式，呈现出丰富多彩的艺术风貌。2024年7月（具体日期待定），艺术节活动将在英国露丝公学以及露丝古镇精彩上演。逾60位国际艺术家的作品汇聚一堂，为您奉献一场跨足全球的视觉盛宴。别错过这个令人振奋的艺术之旅，一同沉浸于青年创意的奇妙世界！`,
    msg6: `岳敏君青少年艺术中心`,
    msg7: `岳敏君青少年艺术中心是一家位于英国的艺术慈善机构，旨在为在世界青少年人才的艺术成长提供一个全球化的发展平台。岳敏君青少年艺术中心，立足于国际视野，通过多元的国际文化和艺术交流项目，助力于青少年的艺术梦想和艺术创新。这是一个促进国际青少年的艺术未来发展的孵化器，致力于于培养青少年艺术实践形式的多样性，包括并不限于视觉艺术，文学，影视，音乐等领域。 
    `,
    msg8: `“青少年是我们的未来。我们希望提供一个国际化的艺术平台，展现来自不同国家和地区的青少年艺术家们的创造力和想象力，并且鼓励青少年艺术家们在这里进行交流和发展。”-岳敏君 `,
  },
  International: {
    msg1: `露丝教育`,
    msg2: `短期国际教育项目`,
    msg3: `我们致力于创造一流的国际研学体验`,
    msg4: `我们致力于营造一个热情与学习交融的环境，创造卓越的学习体验。我们的项目由教育专家精心策划，希望为学生提供一生难忘的丰富体验，开启新的机遇之门，并促进学生的个人成长。`,
    msg5: `我们的暑期项目提供了独一无二的机遇，让学生们得以亲身体验领先的国际教育。我们的夏校和夏令营为11-18岁的青少年设计。在露丝公学，学生们不仅能够参与丰富的课程，还能深度探索北威尔士那如画般的自然景观。`,
    msg6: `露丝夏校和夏令营`,
    msg7: `露丝公学国际夏校`,
    msg8: `露丝公学艺术营`,
    msg9: `露丝足球营`,
    msg10: `露丝公学创意写作营`,
    msg11: `更多信息请点击`,
    msg12: `研学项目包含内容`,
    msg13: `加入我们的夏季活动吧！`,
    msg14: `住宿`,
    msg15: `所有餐食`,
    msg16: `教学和导师指导`,
    msg17: `短途一日旅行和文化活动`,
    msg18: `全天候支持`,
    msg19: `报告和证书`,
    msg20: `使用露丝公学设施`,
    msg21: `艺术营的艺术用品`,
    msg22: `体验英国精英教育，拓展国际视野`,
    msg23: `更多研学项目详情，欢迎来函咨询`,
    msg24: `邮件`,
    msg25: `关注我们`,
    msg26: `camps@ruthineducation.co.uk`,
    msg27: `更多信息请点击`,
  },
  RuthinSchool: {
    msg1: `露丝公学`,
    msg2: `简介`,
    msg3: `露丝公学 （Ruthin School）始建于1284 年， 至今有740年历史，是英国最古老的学校之一。1574 年，威斯敏特院长加布里埃尔· 古德曼(Gabriel Goodman) 重建露丝公学，将其作为北威尔士的卓越学术中心，并获得皇家宪章。1984 年，英国女王伊丽莎白二世(Queen Elizabeth II)访问露丝公学，纪念学校成立 700 周年。`,
    msg4: `坐落在风景壮丽的英国北威尔士，露丝公学七百多年的历史浓缩了英国历史中的丰富遗产和文化传统。我们始终在不断进步，汲取古老的智慧，并与新的思维相结合。露丝公学着重培养学生的学术发展和独立批判思维，帮助学生们掌握未来社会所需的技能。我们着眼于未来，帮助年轻一代思考如何去努力地追寻梦想，并影响世界。 露丝公学的学术成绩一直位居英国前茅。`,
    msg5: `自1284年以来，露丝公学致力于培育未来一代`,
    msg6: `露丝公学亮点介绍`,
    msg7: `露丝公学始建于1284年，至今有近740年历史，是英国最古老的学校之一。1574年，威斯敏斯特院长Gabriel Goodman重建露丝公学，将其作为北威尔士的卓越学术中心，并获得皇家宪章。1984年，英国女王伊丽莎白二世(Queen Elizabeth II)访问露丝公学，纪念学校成立 700周年。`,
    msg8: `悠久历史&皇室亲睐`,
    msg9: `在露丝公学，A-Level阶段的A*和A等级的比例超过60%。超过90%的毕业生进入剑桥大学、牛津大学、伦敦帝国学院、伦敦政治经济学院、伦敦大学学院和华威大学等知名大学深造。`,
    msg10: `成绩卓越`,
    msg11: `学校提供GCSE和A-Level课程，致力于高质量的教学，并专注于个人发展，释放学生的潜力。`,
    msg12: `课程多样`,
    msg13: `作为一所国际化的男女合校寄宿私立学校，露丝公学旨在提供多元化和全球化的教育。`,
    msg14: `国际化教育`,
    msg15: `740年<br>历史`,
    msg16: `G5录取率`,
    msg17: `超过20门<br>A-Level和GCSE科目供选择`,
    msg18: `7天24 小时学生服务`,
    msg19: `未来无限可能`,
    msg20: `2023年，露丝公学在Best School 的英国混合寄宿私立中学排行榜中名列第七。`,
  },
  TalentAward: {
    msg1: `岳敏君青少年艺术奖`,
    msg2: `2024 岳敏君青少年艺术奖（Y Talent Award），是一项国际视觉艺术奖项，旨在表彰杰出青少年艺术家的创造力，以支持他们未来的艺术旅程和职业生涯。岳敏君青少年艺术奖，由国际著名艺术家岳敏君发起，岳敏君青少年艺术中心（Y Art Centre）主办，英国名校露丝公学（Ruthin School)和露丝艺术教育基金会(Ruthin Foundation of Arts and Education)筹备组承办。本届艺术奖设置10项提名奖，并包括1项大奖，将颁发给本艺术奖组委会选出的来自全球的10名，年龄在13至18岁的青少年艺术家。评审团由五位来自岳敏君青少年艺术中心的荣誉成员组成。十位提名获奖者以及所有入选艺术家的作品，将受邀于2024年7月在英国露丝公学展出，并有机会在岳敏君青少年艺术中心的出版物上发表。`,
    msg3: `评委们主要将从“创新力，创作力和表现力”这三个角度来评选出本届奖项的作品。首先，评委们将寻求能够突破传统美学的界限并挑战现状，展现独特和创新的媒介方式的艺术作品。作品的原创性和独特的艺术声音，以及艺术家通过视觉语言传达引人入胜的叙述的能力将受到高度重视；其次，作品所体现技能和工艺也将是评选标准的一部分，艺术家对细节、成分和材料综合使用和艺术表现是其中关键考虑因素；此外，评委们也期待能够寻找到唤起真实情感、引发深思熟虑或引起观众共鸣的作品。`,
    msg4: `奖项设置`,
    msg5: `一名岳敏君青少年奖：奖项得主将获得总价值10000英镑的奖励，其中包括5000英镑现金，价值5000英镑的艺术家驻留基金；`,
    msg6: `十名岳敏君青少年奖提名奖：本奖项提供价值5000英镑的艺术家驻留基金；
    艺术家驻留地点，位于中国贵州遵义的十二背后风景区，驻留期间为10天。5000英镑的艺术驻地基金，包含艺术家的驻留期间内的五星级酒店和餐饮费用。（可能涉及到的其他费用，包括旅行，保险等，不包含在本基金内。）具体驻留时间可根据艺术家的具体情况而定。艺术驻留有效期从本奖项颁布之日2024年6月28日起，至2024年12月31日截止。`,
    msg7: `申请资格 `,
    msg8: `我们欢迎来自世界各地的年龄在13至18岁之间青少年艺术家申请。`,
    msg9: `本次奖项为开放主题； `,
    msg10: `所有参赛作品必须附上不超过100字的简短描述，以解释作品的含义和创作背景；`,
    msg11: `所有参赛作品必须是申请人的原创作品，不得是现有艺术作品的复制品。`,
    msg12: `申请须知 `,
    msg13: `免费申请； `,
    msg14: `本次奖项为开放主题； `,
    msg15: `欢迎所有形式和媒介的视觉艺术作品，作品无尺寸限制；`,
    msg16: `请每位艺术家提交一件艺术品；`,
    msg17: `申请开放时间，2024年3月11日星期一，中午12点。申请截止时间，2024年4月12日星期五，中午12点（英国格林威治标准时间）。请各位申请者通过在线系统，在申请系统开放的有效期间内，完成 2024年岳敏君青少年艺术奖申请； `,
    msg18: `入围艺术家将于 2024年5月10日星期五，通过岳敏君青少年艺术奖官网和官方电子邮件查询入选结果； `,
    msg19: `所有入围作品均需在 2024年6月10日星期五，中午12点之前，通过邮寄或亲自递送的方式送至英国露丝公学，以便纳入2024年岳敏君青少年艺术奖展览的提名；地址：Ruthin School, Mold Road, Ruthin, Denbighshire, LL15 1EE, UK 。`,
    msg20: `联系我们`,
    msg21: `如您有任何疑问，请发送电子邮件至：`,
    msg22: `arts@ruthineducation.co.uk`,
    msg23: `时间流程`,
    msg35: ``,
    msg36: `（所有时间均为英国格林威治标准时间）`,
    msg24: `申请系统开放：2024年3月11日，星期一，中午12点； `,
    msg25: `评审团公布：2024年3月11日，星期一，中午12点； `,
    msg26: `申请截止时间：2024年4月12日，星期五，中午 12点；`,
    msg27: `入围名单公布：2024年5月10日，星期五，中午12点； `,
    msg28: `展览开幕和颁奖典礼：2024 年6月28日，星期五，傍晚6点至8点；`,
    msg29: `展览开放：2024年7月3日至7月26日`,
    msg30: `2024年3月11日<br>开始接受<br>在线申请`,
    msg31: `距离截止时间还有`,
    msg32: `岳敏君青少年艺术奖（Y Talent Award），是一项国际视觉艺术奖项，旨在表彰杰出青少年艺术家的创造力，以支持他们未来的艺术旅程和职业生涯。`,
    msg33: `更多信息`,
    msg34: `视觉艺术作品征集启动`,
    msg37: `注册`,
    msg38: `登录`,
    msg39: `别错过展现自己创造力的机会！`,
    msg40: `递交`,
    msg41: `你的艺术作品！`,
    msg42: `现在`,
  },
  home: {
    msg1: `关于露丝教育`,
    msg2: `露丝教育（Ruthin Education） `,
    msg3: `创立的宗旨是为全球学生`,
    msg4: `提供优质和独特的教育资源。`,
    msg5: `更多信息`,
    msg6: `为什么选择露丝教育`,
    msg7: `露丝公学`,
    msg8: `这所声望卓著的英国寄宿学`,
    msg9: ``,
    msg10: `校是我们教育项目的核心。`,
    msg11: ``,
    msg12: `更多信息`,
    msg13: `短期国际教育项目`,
    msg14: `在露丝公学拓展`,
    msg15: `您的视野。`,
    msg16: `更多信息`,
    msg17: `露丝艺术教育`,
    msg18: `更多信息`,
    msg19: `更多信息`,
    msg20: `更多信息`,
    msg21: `更多信息`,
    msg22: `更多信息`,
    msg23: `更多信息`,
  },
  about: {
    msg1: `关于`,
    msg2: `我们`,
    msg3: `露丝教育（Ruthin Education）创立的宗旨是为全球学生提供优质和独特的教育资源。

    作为我们主要的运营中心，露丝公学（Ruthin School）是一所拥有740年悠久历史的顶尖英国私立寄宿中学，她卓越的教学成果彰显了我们在国际教育领域的雄厚实力和坚实基础。
    
    除了在露丝公学的核心资源之外，我们还精心提供多样化丰富的国际文化交流和艺术教育机会，旨在引导学生探索无限的个人潜力。我们坚信这些独特的体验不仅为学生们的学习旅程添彩，同时也有助于拓展他们的国际视野，为在当今多元社会中取得卓越成就提供有力支持。`,
    msg4: `我们的使命`,
    msg5: `露丝教育集团希望提供一种变革性的、全球化的教育，培养学生对跨文化理解。通过多样化的课程，我们给予学生在多元化的的技能。我们不仅希望成为学生们的学术的引领者，还希望塑造出不仅在学业上出色，而且在全球社会中有贡献的学生。总的来说，我们的使命是培养未来的全面发展、具有文化意识的领导者。`,
    msg6: `我们的愿景与价值`,
    msg7: `尊重`,
    msg8: `责任`,
    msg9: `诚信`,
    msg10: `仁爱`,
    msg11: `勇气`,
  },
  submit: {
    msg1: `我的账户`,
    msg2: `如何递交作品`,
    msg3: `递交作品的截止日期是2024年4月12日星期五，中午12点(英国格林威治标准时间)。请在此截止日期之前提交或编辑你的作品。`,
    msg4: `您可以递交一件作品。`,
    msg5: `您可以递交一到四张作品的图片以及作品的详细信息，包括标题、材料媒介和简短描述。`,
    msg6: `在截止日期之前，您可以将您提交的作品保存为草稿，并在稍后递交。`,
    msg7: `有任何问题请随时联系我们`,
    msg8: `现在递交`,
    msg9: `我的作品`,
    msg10: `您没有递交任何内容。`,
    msg11: `递交作品`,
    msg12: `我的个人信息`,
    msg13: `姓名`,
    msg14: `学校详细信息`,
    msg15: `更新您的详细信息`,
    msg16: `更改密码`,
    msg17: `电子邮件`,
    msg18: `更多信息`,
    msg19: `投稿指导`,
    msg20: `征集参赛作品海报`,
    msg21: `条款和条件`,
  },
  Registration: {
    msg1: `账号注册`,
    msg2: `岳敏君青少年艺术家奖`,
    msg3: `公开征集视觉艺术作品`,
    msg4: `学校详情`,
    msg5: `请填写下列信息。`,
    msg6: `我已经年满13岁或以上`,
    msg7: `学校名称`,
    msg8: `学校地址 `,
    msg9: `街道地址`,
    msg10: `街道地址2`,
    msg11: `城市`,
    msg12: ` 州/地区`,
    msg13: `邮政编码`,
    msg14: `个人信息`,
    msg15: `请填写下列信息`,
    msg16: `姓名`,
    msg17: `名字 `,
    msg18: `姓氏`,
    msg19: `生日`,
    msg20: `邮箱`,
    msg21: `电话`,
    msg22: `地址`,
    msg23: `密码`,
    msg24: `确认密码`,
    msg25: `注册接收有关于岳敏君青少年艺术家奖未来的机
    会与新闻的邮件更新。
    `,
    msg26: `我同意下面的服务条款。(您需要接受我们的条款
      和条件以参与本次艺术奖评选)`,
    msg27: `提交`,
    msg28: `条款和条件`,
    msg29: `国家和地区`,
  },
  SubmitWork: {
    msg1: `递交页面`,
    msg2: `您正在以 xxx 身份登录`,
    msg3: `登出`,
    msg4: `作品信息`,
    msg5: `学生姓名`,
    msg6: `年级`,
    msg7: `学生年龄`,
    msg8: `作品标题`,
    msg9: `（如果您的作品在未来展出，这将出现在展出作品的标签上）`,
    msg10: `描述`,
    msg11: `（请描述关于这件作品的创作灵感。不超过 100 个字。）`,
    msg12: `作品类型`,
    msg13: `作品材料（可选）`,
    msg14: `您可以在此处添加用于制作此艺术品的特定材料，例如“画布上的丙烯酸涂料”`,
    msg15: `尺寸`,
    msg16: `上传您的作品`,
    msg17: `上传 `,
    msg18: `你可以额外上传1-3张关于这件作品附加图（例如不同角度，细节）来更好地展现你的作品。`,
    msg19: `视频`,
    msg20: `添加您的视频链接`,
    msg21: `您的艺术作品可以是用手机（同一艺术作品最多可拍摄 4 张照片）、平板电脑或数码相机拍摄
    的照片。照片应该准确表现您的作品。如果您的作品被选用于展览，我们将在网上使用这些图
    像。`,
    msg22: `如果您的作品为视频剪辑，请在文本框中包含该视频的链接。您可以将作品上传到
    Wetransfer/Youtube/Google Drive/One Drive 或其他允许公众查看的云存储。如果您在
    将其上传到公共域时遇到问题，请联系我们。`,
    msg23: `如何为作品拍摄优质的照片：`,
    msg24: `- 确保将整个艺术作品包含在图像中，必要时进行裁剪`,
    msg25: `- 如果可能的话，在白天拍摄照片可以获得更好的光线`,
    msg26: `后退`,
    msg27: `保存为草稿`,
    msg28: `保存并提交`,
    msg29: `请上传一张您的作品图片作为主要展示图片`,
    msg30: `A4 纵向 - 297 x 210 毫米`,
    msg31: `A4 横向 - 210 x 297 毫米`,
    msg32: `A3 纵向 - 297 x 420 毫米`,
    msg33: `A3 横向 - 420 x 297 毫米`,
    msg34: `A2 纵向 - 420 x 594 毫米`,
    msg35: `书本 `,
    msg36: `绘画（颜料喷漆为主） `,
    msg37: `装置 `,
    msg38: `混合媒体 `,
    msg39: `模型 `,
    msg40: `绘画（素描速写为主） `,
    msg41: `表演 `,
    msg42: `摄影 `,
    msg43: `印刷品 `,
    msg44: `雕塑  `,
    msg45: `声音 `,
    msg46: `纺织品  `,
    msg47: `视频 `,
    msg48: `您确定要提交吗？提交后将无法修改。如果需要稍后编辑，请先保存为草稿。 `,
    msg49: `确认`,
    msg50: `取消`,
    msg51: `提示`,
    msg52: `图片`,
    msg53: `作品信息不完整`,
    msg54: `截止至英国时间2024年4月12日，星期五，中午 12点前，所有提交的作品信息和作品图片均可被修改。`,
  },
  UploadDetails: {
    msg1: `编辑我的姓名和学校详细信息`,
    msg2: `您正在以 xxxxxx 身份登录`,
    msg3: `登出`,
    msg4: `个人资料`,
    msg5: `名`,
    msg6: `姓`,
    msg7: `学校详情`,
    msg8: `学校 `,
    msg9: `更新信息`,
    msg10: `后退`,
  },
  ChangePassword: {
    msg1: `更改密码`,
    msg2: `您正在以 xxxxxx 身份登录`,
    msg3: `不是您吗？ 登出`,
    msg4: `电子邮箱 `,
    msg5: `电子邮箱地址`,
    msg6: `密码`,
    msg7: `当前密码`,
    msg8: `新密码`,
    msg9: `最少8个字符`,
    msg10: `新密码确认`,
    msg11: `更新`,
    msg12: `后退`,
    msg13: `两次密码输入不一致`,
  },
  Submissionguidance: {
    msg1: `提交指南`,
    msg2: ``,
    msg3: `“岳敏君青少年艺术奖”是一个国际视觉艺术竞赛，旨在嘉奖来自全球各地年龄在13至18岁之间的杰出青年艺术家的创造力。`,
    msg4: `艺术作品可以涉及任何主题。`,
    msg5: `视觉艺术作品可以包括绘画、装置艺术、混合媒体、模型、绘画、表演、摄影、印刷、雕塑、声音、纺织品或视频。`,
    msg6: `首轮提交将是提交电子版本。如果您的作品入选，我们将与您联系，并安排将艺术品送到露丝公学，考虑在7月的展览中展出。`,
    msg7: `所有参赛作品必须附有不超过100字的简短描述，为艺术品提供详细创作灵感和背景。`,
    msg8: `所有参赛作品必须是申请人的原创作品，不得是现有艺术品的复制品或再创作。 `,
    msg9: `由AI创作的艺术作品将不被接受。`,
    msg10: `评委们寻求创造力、技术造诣和情感共鸣的融合。与岳敏君的作品类似，他们将寻找表现对媒介独特而创新的艺术品，推动传统美学的界限并挑战现状。评委们将关注独创
    性和独特的艺术语言，以及通过视觉语言传达的引人入胜的故事或信息的能力。技术技能和工艺也将起到重要作用，细节、构图和材料的使用是关键考虑因素。此外，评委
    们将寻找能引发真实情感、引起深思熟虑的作品，或在观众中引起直观反应的作品。`,
    msg11: `艺术品的照片呈现非常重要，可能会影响评委对艺术品的看法。请阅读下面我们的“如何拍摄艺术品”部分，获取一些建议和技巧。我们每位申请人只接受一件作品，并且
    不接受迟交的作品。`,
    msg12: `教师们，如果您打算为班上的每位学生提交作品，我们建议不要提交视觉相似的作品。我们还建议您在截止日期前尽早开始上传您的作品图像，以避免遇见最后一刻的技术
    问题。`,
    msg13: `我们迫切希望看到每一件提交的作品，迫不及待地想看看我们的年轻艺术家们今年将创造什么！`,
    msg14: `如何拍摄您的艺术品`,
    msg15: `当您提交作品参加岳敏君青少年艺术奖时，我们要求您分享一到四张作品的照片。如果艺术品被选中参加展览，这些照片将会在网上展示。因此拍摄一张能够真实展现艺术
    品外观的好照片非常重要。`,
    msg16: `以下是一些建议，帮助您拍摄好的照片：`,
    msg17: `使用中性背景，使注意力集中在艺术品上。`,
    msg18: `争取柔和均匀的光线，在白天拍摄是避免在艺术品上产生阴影的最佳选择。`,
    msg19: `如果可能的话，拍摄未装裱的艺术品，因为玻璃或塑料可能会产生眩光和反射。`,
    msg20: `闪光灯可能会产生眩光，在有足够光线的情况下请尽量避免使用。`,
    msg21: `保持相机稳定，将作品放在平面或使用三脚架可以有所帮助。`,
    msg22: `对于较小的艺术品、铅笔画和摆在皱巴巴的纸上的作品，扫描仪的效果会更好。`,
    msg23: `确保整个艺术品都出现在照片中，包括所有边缘以及任何用于雕塑的基座或支架。事后请裁剪掉任何背景。`,
    msg24: `拍摄几张照片，选择最好的一张提交。`,
    msg25: `对于三维作品，您可以上传最多4张照片，展示不同角度的作品。`,
    msg26: `图像必须是JPG或PNG格式，大小不得超过5MB。`,
    msg27: `如果您将视频剪辑作为作品提交，请在描述框中包含视频剪辑的链接。您可以将作品上传到Wetransfer/Youtube/Google Drive/One Drive或其他允许公开查看的云存
    储。如果在上传到公共领域时遇到困难，请与我们联系。`,
    msg28: ``,
    msg29: `arts@ruthineducation.co.uk.`,
  },
  terms: {
    msg1: `岳敏君青少年艺术奖2024 - 条款与条件`,
    msg2: `欢迎参加岳敏君青少年艺术奖2024，这是一场国际视觉艺术竞赛。在提交您的作品之前，请仔细阅读以下条款与条件：
    `,
    msg3: `1. 艺术家及其作品：`,
    msg4: `1.1 本次比赛面向全球年龄在13至18岁的艺术家开放。`,
    msg5: `1.2 每位艺术家只能提交一件作品。`,
    msg6: `1.3 提交截止日期为2024年4月12日中午（GMT）。`,
    msg7: `1.4 艺术家需对作品中的所有权利进行授权。`,
    msg8: `1.5 所有邮件通讯请通过邮箱arts@ruthineducation.co.uk进行。 `,
    msg9: `1.6 入围艺术家将于2024年5月10日收到通知。入选艺术家的作品需在2024年6月10日前送至露丝公学。我们将在露丝教育网站上展示入选艺术家的作品并用于宣传目的。`,
    msg10: `1.7 2024年7月在露丝公学举办展览。颁奖典礼定于2024年6月28日。`,
    msg11: `1.8 展览作品（除一等奖外）可于2024年8月在露丝公学领取。具体时间将通过电子邮件确认。您的作品可能有机会在露丝教育的合作画廊或其他机构展出，具体安排将与您和我们
    的合作伙伴商议并达成一致。`,
    msg12: `1.9 您必须是参赛作品的所有者，并确认所提交的作品是您原创的。您必须持有所有参赛作品的道德和知识产权。`,
    msg13: `1.10 版权归艺术家所有，但露丝教育和Y艺术中心有权为宣传目的复制展出的作品。`,
    msg14: `1.11 访客可以为个人非商业用途拍摄展出的作品。`,
    msg15: `1.12 艺术家需对展览到最终领取期间的作品损坏或丢失负责。露丝教育及/或其代理人对委托给他们的艺术品的任何损失或损坏概不负责，无论是由于火灾违约、错误、疏忽、承运
    人、包装商或其他人的盗窃，还是在由于露丝教育雇佣的员工导致的损失或损坏，所有货物均由艺术家所有者承担风险，露丝教育及/或其代理人在法律允许的最大范围内排除所有责
    任。强烈建议您为您的作品购买保险。`,
    msg16: `2. 入围作品的交付：`,
    msg17: `2.1 艺术家负责安排并支付作品交付至露丝公学。`,
    msg18: `2.2 邮寄的作品需采用适当的包装。`,
    msg19: `2.3 我们不负责包装或提供归还的包装材料。`,
    msg20: `2.4 艺术家需要负担快递寄送作品的费用，包括海关收费和关税。请与快递公司核实海关清关事宜。`,
    msg21: `3. 作品的领取/归还：`,
    msg22: `3.1 艺术家可以在2024年8月领取其作品，具体安排将与露丝教育商议。`,
    msg23: `3.2 未领取的作品将于2024年8月31日后处理。`,
    msg24: `4. 奖项：`,
    msg25: `4.1 一等奖岳敏君青少年艺术奖：现金5000英镑，以及在贵州遵义的艺术驻地10天。`,
    msg26: `4.2 十个提名奖：在贵州遵义的艺术驻地10天。`,
    msg27: `4.3 艺术驻地：您需要自行安排前往和离开驻地的旅行。艺术驻地需在2024年12月31日前组织完成。露丝教育将支付驻地期间的住宿（五星级酒店）和膳食费用。`,
    msg28: `5. 父母同意：`,
    msg29: `5.1 未满18岁的艺术家必须得到父母或法定监护人的同意才能参加比赛。`,
    msg30: `5.2 提交作品即视为父母或法定监护人已授予其子女参赛的许可。`,
    msg31: `6.条款与条件的修改`,
    msg32: `6.1 露丝教育可在任何时候通过合理通知修改艺术奖的条款和条件（包括提交作品和参与入围和获奖艺术家的比赛），包括但不限于在其网站上发布修订后的条款，该修改后的条款
    与条件应对您具有约束力。`,
    msg33: `6.2 这些条款与条件将受英国法律管辖。因此，任何争议将受英格兰和威尔士法院的管辖。`,
    msg34: `6.3 从您提交作品的那一刻起，这些规则对您具有约束力。`,
    msg35: `7. 联系方式：`,
    msg36: `如有其他任何问题，请通过电子邮件联系我们，邮箱为arts@ruthineducation.co.uk。`,
    msg37: `通过提交您的作品，您承认您已阅读、理解并同意遵守这些条款与条件。期待在岳敏君青少年艺术奖2024中展示您的创造力！`,
  },
  login: {
    msg1: `登录`,
    msg2: `没有账号?`,
    msg3: ` 注册账号`,
    msg4: `电子邮箱 `,
    msg5: `密码`,
    msg6: `记住密码`,
    msg7: `忘记密码?`,
  },
  forgot: {
    msg1: `忘记密码`,
    msg2: `电子邮箱?`,
    msg3: ` 发送重置密码邮件`,
    msg4: ` 更改成功`,
    msg5: ` 显示密码`,
    msg6: ` 邮件已发送您的邮箱`,
    msg7: ` 阅读更多`,
    msg8: ` 收回`,
    msg9: ` 上传成功`,
    msg10: ` 提交成功`,
    msg11: `图片为PNG/JPEG/JPG格式并大小不超过5MB`,
    msg12: `用户不存在/密码错误`,
    msg13: `未找到此邮箱`,
    msg14: `原密码错误`,
    msg15: `密码至少6位`,
    msg16: `长`,
    msg17: `宽`,
    msg18: `高`,
    msg19: `提交成功。比赛进程与结果会在此处更新`,
    msg20: `审核中`,
    msg21: `比赛结果： 很抱歉！你的作品未入围`,
    msg22: `恭喜！你的作品已入围。`,
    msg24: `该邮箱已注册`,
  },
};
