<template>
    <div class="top">
        <div class="pcdiv">
            <!-- <div class="login_div">
                <button class="login_but">
                    <img src="../../assets/svg/user.svg" alt="">
                    <div>登入</div>
                </button>
            </div> -->
            <div class="menudiv">
                <div class="logodiv">
                    <img class="logoimg" src="@/assets/static/logo.jpg" alt="">
                    <div>
                        <p class="logoname">{{ $t('headmenu.msg8') }}</p>
                        <p class="logoname">{{ $t('headmenu.msg9') }}</p>
                    </div>
                </div>
                <ul class="menuul">
                    <li :class="parentmsg == 0 ? 'choosetab' : ''"><a href="https://ruthineducation.co.uk/#/" target=""
                            rel="noopener noreferrer">{{ $t('headmenu.msg1') }}</a></li>
                    <li :class="parentmsg == 1 ? 'choosetab' : ''"><a href="https://ruthineducation.co.uk/#/aboutUs"
                            target="" rel="noopener noreferrer">{{ $t('headmenu.msg2') }}</a>
                    </li>
                    <li :class="parentmsg == 2 ? 'choosetab' : ''"><a href="https://ruthineducation.co.uk/#/ruthin"
                            target="" rel="noopener noreferrer">{{ $t('headmenu.msg3') }}</a></li>
                    <li class="itemmenup" :class="parentmsg == 3 ? 'choosetab' : ''"><a
                            href="https://ruthineducation.co.uk/#/art" target="" rel="noopener noreferrer">{{
                                $t('headmenu.msg4') }}</a>
                        <ul class="itemmenu">
                            <li><a href="https://ruthineducation.co.uk/#/TalentAward" target="" rel="noopener noreferrer">{{
                                $t('headmenu.msg6') }}</a></li>
                            <li><a href="https://ruthineducation.co.uk/#/artFestival" target="" rel="noopener noreferrer">{{
                                $t('headmenu.msg10') }}</a></li>
                        </ul>
                    </li>
                    <li :class="parentmsg == 4 ? 'choosetab' : ''"><a href="https://ruthineducation.co.uk/#/International"
                            target="" rel="noopener noreferrer">{{
                                $t('headmenu.msg5') }}</a>
                    </li>
                    <!-- <li :class="parentmsg == 5 ? 'choosetab' : ''" @click="Changetab('/Contact')">Contact Us</li> -->
                    <li :class="parentmsg == 6 ? 'choosetab' : ''" @click="changeLang">{{ $t('headmenu.msg7') }}</li>
                </ul>
            </div>

        </div>
        <div class="modile">
            <div class="mobilediv">
                <div class="logodiv">
                    <img class="logoimg" src="@/assets/static/logo.jpg" alt="">
                    <div>
                        <p class="logoname">{{ $t('headmenu.msg8') }}</p>
                        <p class="logoname">{{ $t('headmenu.msg9') }}</p>
                    </div>
                </div>
                <div>
                    <img @click="Handlemenu" class="menuimg" src="../../assets/static/menu.png" alt="menu.png">
                </div>
            </div>

        </div>
        <!-- 菜单 -->
        <div v-if="showmenu" class="mobilemenuul animate__animated animate__fadeInDown" :style="{ height: viewportHeight }">
            <div class="closeimg">
                <img @click="HandleClosemenu" src="../../assets/static/58.png" alt="">
            </div>
            <ul>
                <li :class="parentmsg == 0 ? 'choosetab1' : ''"><a href="https://ruthineducation.co.uk/#/" target=""
                        rel="noopener noreferrer">{{ $t('headmenu.msg1') }}</a></li>
                <li :class="parentmsg == 1 ? 'choosetab1' : ''"><a href="https://ruthineducation.co.uk/#/aboutUs" target=""
                        rel="noopener noreferrer">{{ $t('headmenu.msg2') }}</a></li>
                <li :class="parentmsg == 2 ? 'choosetab1' : ''"><a href="https://ruthineducation.co.uk/#/ruthin" target=""
                        rel="noopener noreferrer">{{ $t('headmenu.msg3') }}</a></li>
                <li class="tabmenu">
                    <img :class="showtab ? 'tabmenuclass' : ''" @click="HandleOpentab" src="../../assets/static/59.png"
                        alt="">
                    <span :class="parentmsg == 3 ? 'choosetab1' : ''"><a href="https://ruthineducation.co.uk/#/art"
                            target="" rel="noopener noreferrer">{{ $t('headmenu.msg4') }}</a></span>
                </li>
                <li v-if="showtab" class="TalentAward"><a href="https://ruthineducation.co.uk/#/TalentAward" target=""
                        rel="noopener noreferrer">{{
                            $t('headmenu.msg6') }}</a></li>
                <li v-if="showtab" class="TalentAward"><a href="https://ruthineducation.co.uk/#/artFestival" target=""
                        rel="noopener noreferrer">{{ $t('headmenu.msg10') }}</a></li>
                <li :class="parentmsg == 4 ? 'choosetab1' : ''"><a href="https://ruthineducation.co.uk/#/International"
                        target="" rel="noopener noreferrer">{{ $t('headmenu.msg5') }}</a></li>
                <li @click="changeLang">{{ $t('headmenu.msg7') }}</li>
            </ul>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'HeadMenu',
    props: ['parentmsg'],
    data() {
        return {
            msg: '头部',
            viewportHeight: '',
            showmenu: false,
            showtab: false
        };
    },
    mounted() {
        this.viewportHeight = window.innerHeight; // 获取视口高度
        console.log(window.innerHeight)
    },
    methods: {
        //禁止滚动

        stopScroll() {
            var mo = function (e) { e.preventDefault(); };
            document.body.style.overflow = 'hidden';
            document.addEventListener("touchmove", mo, false);//禁止页面滑动
        },

        /***取消滑动限制***/
        canScroll() {
            var mo = function (e) { e.preventDefault(); };
            document.body.style.overflow = '';//出现滚动条
            document.removeEventListener("touchmove", mo, false);
        },
        HandleOpentab() {
            this.showtab = !this.showtab
        },
        Handlemenu() {
            this.showmenu = true;
            this.stopScroll()
        },
        HandleClosemenu() {
            this.showmenu = false;
            this.canScroll()
        },
        Changetab(item) {
            console.log('获取', item)
            this.canScroll()
            this.$router.push({
                path: item
            })
        },
        changeLang() {
            if (this.$i18n.locale === 'zn') {   // 判断当前语言
                localStorage.setItem('lang', "en")
                this.$i18n.locale = 'en'   // 设置当前语言
            } else {
                localStorage.setItem('lang', "zn")
                this.$i18n.locale = 'zn'
            }
        }
    }
    // props: {
    //   msg: String
    // }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top {
    width: 100%;
    position: relative;
    background: #fff;
    box-sizing: border-box;
    padding-top: 32px;
}

.modile {
    display: none;
}

.login_div {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin: 0 auto;
}

.login_but {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 6px 7px 6px 27px;
    color: #fff;
    background-color: rgb(129, 150, 153);
}

.login_but img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
}

.menudiv {
    display: flex;
    width: 85%;
    margin: 0 auto;
    justify-content: space-between;
}

.logoimg {
    width: 75px;
    margin-right: 15px;
}

.logodiv {
    display: flex;
    align-items: center;
}

.menuul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* position: relative; */
}

.itemmenup {
    position: relative;
}

.itemmenup:hover .itemmenu {
    display: block;

}

.menuul li {
    box-sizing: border-box;
    padding: 0 5px;
    line-height: 42px;
    margin-left: 10px;
    cursor: pointer;
}

.itemmenu {
    display: none;
    width: 320px;
    position: absolute;
    right: 0;
    background-color: #fff;

}

.itemmenu li {
    text-align: right;
    line-height: 32px;
}

.itemmenu li:hover {
    color: #dbc96c;
}

.logoname {
    font-weight: bold;
    font-size: 21px;
    word-break: normal;
}

.pcdiv {
    display: block;
    box-sizing: border-box;
    padding-bottom: 20px;
}

.choosetab {
    color: rgb(129, 150, 153);
}

/* 手机屏幕的字体大小 */
@media screen and (max-width: 1000px) {
    .pcdiv {
        display: none;
    }

    .modile {
        display: block;
    }

    .mobilediv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 15px;
    }

    .menuimg {
        width: 44px;
    }

    .mobilemenuul {
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        background: #fff;
        z-index: 999;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    .mobilemenuul ul li {
        font-size: 22px;
        line-height: 68px;
        color: rgb(167, 178, 159);
        font-weight: 700;
    }

    .closeimg {
        width: 100%;
        text-align: right;
        box-sizing: border-box;
        padding: 0 20px;
        margin-bottom: 30px;
    }

    .closeimg img {
        width: 27px;
        height: 27px;
    }

    .choosetab1 {
        color: #dbc96c !important;
    }

    .noScroll {
        overflow: hidden !important;
    }

    .TalentAward {
        font-weight: 500 !important;
        font-size: 20px !important;
    }

    .tabmenu img {
        width: 30px;
        margin-right: 20px;
    }

    .tabmenu {
        display: flex;
        align-items: center;
    }

    .tabmenuclass {
        transform: rotate(180deg);
    }
}
</style>
  