<template>
  <div id="app">
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {};
  },
  mounted: function () {
    // // 页面开始加载时修改font-size
    // var html = document.getElementsByTagName("html")[0];
    // var oWidth =
    //   document.body.clientWidth || document.documentElement.clientWidth;
    // // 这里的750是指设计图的大小,自己根据实际情况改变
    // html.style.fontSize = (oWidth / 750) * 100 + "px";
    // console.log("rem:", html.style.fontSize);
  },
  methods: {
    // 点击其他输入框
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
}

#nav {
  /* padding: 30px; */
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input {
  margin: 0px;
  padding: 0px;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

ul,
li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

input {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: none;
}

input[type="button"],
input[type="submit"],
input[type="file"],
button {
  cursor: pointer;
  -webkit-appearance: none;
}

html {
  width: 100%;
  font-family: "SourceHanSansCN-Normal";
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  font-family: "SourceHanSansCN-Normal";
}

canvas {
  display: block;
  position: relative;
}

img {
  border: 0 none;
  outline: 0 none;
}

em {
  font-style: normal;
}

i {
  font-style: normal;
}

button {
  outline: none;
  border: none;
  font-size: inherit;
  font-family: inherit;
}

* {
  word-wrap: break-word;
  word-break: break-all;
  margin: 0;
  padding: 0;
  list-style: none;
}

body,
h1,
ul,
ol,
h2,
p,
figure,
h3 {
  margin: 0;
  padding: 0;
}

input[type="button"],
input[type="submit"],
input[type="file"],
button {
  cursor: pointer;
  -webkit-appearance: none;
}
</style>
