<template>
  <!-- <h2 class="iqbOMh animate__animated animate__bounceInLeft">Write about</h2>> -->

  <div class="container">
    <img class="bgimg1" :style="{ top: windowHeight }" src="../assets/static/new.png" alt="">

    <!-- <Head_Menu></Head_Menu> -->
    <div class="contop">
      <head_Menu :parentmsg="0"></head_Menu>
      <!-- <img class="bgimg2" src="../assets/static/2.png" alt=""> -->
      <div>
        <div>
          <div class="topimg">
            <P class="RuthinEducati animate__animated animate__fadeInLeft animate__slow">{{ $t('headmenu.msg8') }} {{
              $t('headmenu.msg9') }}</P>
            <!-- <P class="Learning animate__animated animate__fadeInLeft animate__slow">Everything About Learning</P> -->
            <div class="animate__animated animate__fadeInLeft animate__slow">
              <button class="buts" @click="Changetab('/about')">{{ $t('home.msg1') }}</button>
            </div>
          </div>
        </div>
        <div>
          <div class="topimg1">
            <P class="RuthinEducati animate__animated animate__fadeInLeft animate__slow">{{ $t('headmenu.msg8') }}{{
              $t('headmenu.msg9') }}</P>
            <!-- <P class="Learning animate__animated animate__fadeInLeft animate__slow">Everything About Learning</P> -->
            <div class="animate__animated animate__fadeInLeft animate__slow">
              <button class="buts" @click="Changetab('/about')">{{ $t('home.msg1') }}</button>
            </div>
          </div>
        </div>
        <!-- about us -->
        <div class="aboutus">
          <div class="aboutusdiv">
            <div class="leftimg">
              <img src="../assets/static/1.png" alt="">
            </div>
            <div class="text">
              <p class="text1 animate__animated animate__fadeInRight animate__slow">{{
                $t('headmenu.msg2') }}</p>
              <p class="text2 animate__animated animate__fadeInRight animate__slow">{{ $t('home.msg2') }}
              </p>
              <p class="text2 animate__animated animate__fadeInRight animate__slow">{{ $t('home.msg3') }}</p>
              <p class="text2 animate__animated animate__fadeInRight animate__slow">{{ $t('home.msg4') }}</p>
              <p class="text3 animate__animated animate__fadeInRight animate__slow" @click="Changetab('/about')">{{
                $t('home.msg5') }}
              </p>
            </div>
          </div>

        </div>
        <!-- Why Ruthin Education -->
        <div class="h_titlediv">
          <div class="h_title">
            <p class="h_titletext animate__animated animate__fadeInRight animate__slow">{{ $t('home.msg6') }}</p>
            <!-- our school -->
            <div class="ourschooldiv">
              <div class="imgdiv">
                <img src="../assets/static/3.png" alt="">
              </div>
              <div class="textdiv">
                <p class="s_text1 animate__animated animate__fadeInRight animate__slow">
                  {{ $t('home.msg7') }}</p>
                <p class="s_text2 animate__animated animate__fadeInRight animate__slow">{{ $t('home.msg8') }}
                </p>
                <p class="s_text2 animate__animated animate__fadeInRight animate__slow">{{ $t('home.msg9') }}
                </p>
                <p class="s_text2 animate__animated animate__fadeInRight animate__slow">{{ $t('home.msg10') }}
                </p>
                <p class="s_text2 animate__animated animate__fadeInRight animate__slow">{{ $t('home.msg11') }}
                </p>
                <p class="s_text3 animate__animated animate__fadeInRight animate__slow"
                  @click="Changetab('/RuthinSchool')">
                  {{ $t('home.msg12') }}</p>
              </div>
            </div>
            <!-- Our Camps -->
            <div class="linediv2">
              <div class="lineimg2">
                <img src="../assets/static/4.png" alt="">
              </div>
              <div class="line2">
                <p class="s_text1 animate__animated animate__fadeInRight animate__slow">{{ $t('home.msg13') }}</p>
                <p class="s_text2 animate__animated animate__fadeInRight animate__slow">{{ $t('home.msg14') }}
                </p>
                <p class="s_text2 animate__animated animate__fadeInRight animate__slow">{{ $t('home.msg15') }}
                </p>
                <p class="line2_text3 animate__animated animate__fadeInRight animate__slow"
                  @click="Changetab('/ShortCoursesCamps')">{{ $t('home.msg16') }}</p>
              </div>
              <div class="lineimg2">
                <img src="../assets/static/5.png" alt="">
              </div>
            </div>
            <!-- Ruthin -->
            <div class="linediv2">
              <div class="line3">
                <p class="s_text1 animate__animated animate__fadeInRight animate__slow" v-html="$t('home.msg17')"></p>
                <!-- <p class="s_text1 animate__animated animate__fadeInRight animate__slow">Art Education</p>
                <p class="s_text2 animate__animated animate__fadeInRight animate__slow">Yue MinJun International Young
                </p>
                <p class="s_text2 animate__animated animate__fadeInRight animate__slow">Artist Award
                </p> -->
                <p class="line2_text3 animate__animated animate__fadeInRight animate__slow"
                  @click="Changetab('/ArtFestival')">{{ $t('home.msg18') }}</p>
              </div>
              <div class="lineimg2">
                <img src="../assets/static/6.png" alt="">
              </div>

              <div class="lineimg2">
                <img src="../assets/static/7.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footpage></Footpage>
    </div>
  </div>
</template>

<script>

export default {
  name: "Home",
  data() {
    return {
      windowHeight: ''
    };
  },
  created() {
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  methods: {
    //获得页面向左、向上卷动的距离
    getScroll() {
      return {
        top: window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      };
    },

    handleScroll() {
      this.windowHeight = '-' + this.getScroll().top / 5 + 'px'
      const scrollTop = this.getScroll().top;
      console.log(scrollTop)
    },
    Changetab(item) {
      // this.canScroll()
      this.$router.push({
        path: item
      })
    },
  }
};
</script>
<style scoped>
body {
  overflow-x: hidden;
}

.topimg1 {
  display: none;
}

.contop {
  width: 100%;
  position: relative;
  /* background: url(../assets/static/head.jpg) no-repeat; */
}

.topimg {
  position: relative;
  height: 800px;
  /* background: url(../assets/static/head.jpg) no-repeat; */
  width: 100%;
  background-position: center;
  box-sizing: border-box;
  padding-top: 70px;
}

.RuthinEducati {
  font-size: 85px;
  font-weight: bold;
  color: #000;
}

.Learning {
  color: #000;
  font-size: 42px;
}

.buts {
  margin-top: 10px;
  width: 159px;
  height: 45px;
  background: #323232;
  color: #fff;
}

.aboutus {
  background: #e0e5e6;

  position: relative;
}

.bgimg1 {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* opacity: 0.8; */
  width: 100%;
}

.aboutusdiv {
  min-height: 400px;
  width: 90%;
  display: flex;
  margin: 0 auto;
  overflow: hidden;
  align-items: center;
}

.text1 {
  font-size: 42px;
  font-weight: bold;
}

.text2 {
  font-size: 18px;
  font-weight: lighter;
  line-height: 32px;
}

.text3 {
  font-size: 17px;
  font-weight: 600;
  margin-top: 24px;
  text-decoration: 2px solid #000;
  text-decoration-line: underline;
  cursor: pointer;

}

.text {
  box-sizing: border-box;
  padding-left: 50px;
  text-align: left;
  color: #000;

}

.topimg {
  display: block;
}

.leftimg img {
  height: 100%;
}

.h_titlediv {
  width: 100%;
  background-color: #fff;
}

.h_title {
  width: 90%;
  margin: 0 auto;
  font-size: 42px;
  font-weight: bold;
  text-align: left;
  box-sizing: border-box;
  padding-top: 60px;
  padding-bottom: 30px;
  color: #000;
}

.ourschooldiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.imgdiv {
  width: 66.66%;
  height: 356px;
}

.imgdiv img {
  width: 100%;
  height: 356px;
  object-fit: cover;
}

.textdiv {
  width: 33.33%;
  height: 356px;
  background: #819699;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding-left: 40px;
}

.s_text1 {
  font-size: 35px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 15px;
}

.s_text2 {
  font-size: 17px;
  color: #fff;
  line-height: 32px;
  font-weight: 100;

}

.s_text3,
.line2_text3 {
  font-size: 17px;
  color: #fff;
  margin-top: 15px;
  position: relative;
  cursor: pointer;
}

.s_text3:after {
  content: '';
  position: absolute;
  width: 93px;
  height: 2px;
  bottom: -4px;
  left: -2px;
  background: #fff;
}

.line2_text3::after {
  content: '';
  position: absolute;
  width: 70px;
  height: 2px;
  bottom: -4px;
  left: -2px;
  background: #fff;
}

.linediv2 {
  display: flex;
  flex-wrap: wrap;
}

.lineimg2 {
  width: 33.33%;
  height: 309px;
}

.lineimg2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.line2 {
  width: 33.33%;
  height: 309px;
  background: rgb(171, 178, 159);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding-left: 40px;
}

.line3 {
  background-color: rgb(129, 150, 153);
  width: 33.33%;
  height: 309px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding-left: 40px;
}

.h_titletext {
  padding-bottom: 30px;
}

.infodiv {
  width: 100%;
  background: #fff;

}

.info {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.titleh2 {
  color: #000;
  margin-bottom: 40px;
}

.info1 {
  width: 27%;
  text-align: left;
}

.info2 {
  width: 46%;
  text-align: left;
}

.infop1 {
  font-size: 17px;
  color: rgb(129, 150, 153);
  line-height: 32px;
  cursor: pointer;
}

.infop2 {
  line-height: 32px;
  cursor: pointer;
}

.iptdiv {
  color: rgb(129, 150, 153);
  display: flex;
  border-bottom: 1px solid rgb(129, 150, 153);
}

.iptdiv1 {
  margin-top: 20px;
  color: rgb(129, 150, 153);
  border-bottom: 1px solid rgb(129, 150, 153);
}

.iptdiv div {
  width: 50%;
}

.iptdiv input {
  width: 50%;
  height: 40px;
  line-height: 40px;
  outline: none;
  border: none;
}

.iptdiv1 input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  outline: none;
  border: none;
}

.Submit {
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  color: rgb(129, 150, 153);
  cursor: pointer;
}

/* 手机屏幕的字体大小 */
@media screen and (max-width: 900px) {
  .bgimg1 {
    display: none;
  }

  .topimg {
    display: none;
  }

  .topimg1 {
    display: block;
    position: relative;
    height: 194px;
    background: url(../assets/static/2.png) no-repeat;
    width: 100%;
    background-position: center;
    box-sizing: border-box;
    padding-top: 30px;
    background-size: cover;
    opacity: 0.8;
    /* margin-bottom: 20px; */
  }

  .RuthinEducati {
    font-size: 34px;
  }

  .Learning {
    font-size: 23px;
  }

  .aboutusdiv {
    width: 100%;
    display: block;
  }

  .text1 {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .text2 {
    font-size: 16px;
  }

  .text {
    padding: 20px;
  }

  .leftimg img {
    width: 100%;
  }

  .h_title {
    width: 100%;
  }

  .h_titletext {
    margin: 0 5%;
    font-size: 26px;
    margin-bottom: 40px;

  }

  .imgdiv {
    width: 100%;
  }

  .textdiv {
    width: 100%;
  }

  .lineimg2,
  .line2,
  .line3,
  .info1,
  .info2 {
    width: 100%;
  }
}
</style>
