import Vue from "vue";
import VueI18n from "vue-i18n";
import zn from "./zn";
import en from "./en";
import locale from "element-ui/lib/locale";
Vue.use(VueI18n); // 全局注册国际化包

// 准备翻译的语言环境信息
const i18n = new VueI18n({
  //  "zn", // 初始化中文
  locale: localStorage.getItem("lang") || "en",
  messages: {
    zn: zn,
    en: en,
  },
});
locale.i18n((key, value) => i18n.t(key, value));
export default i18n;
