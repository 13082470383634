// en.js
import enLocale from "element-ui/lib/locale/lang/en";
export default {
  ...enLocale,
  headmenu: {
    msg1: "Home",
    msg2: "About Us",
    msg3: "Ruthin School",
    msg4: "Arts Education at Ruthin",
    msg5: "International Short Courses and Camps",
    msg6: "Y Talent Award 2024",
    msg7: "English/中文",
    msg8: "Ruthin",
    msg9: "Education",
    msg10: "International Arts Festival",
  },
  footpage: {
    msg1: `Ruthin Education`,
    msg2: `Terms and conditions`,
    msg3: `WeChat`,
    msg4: `Twitter`,
    msg5: `Linkedin`,
    msg6: `Instagram`,
    msg7: ``,
    msg8: `Join the Community`,
    msg9: `Join the Arts Education Community`,
    msg10: `Ruthin Arts Festival`,
    msg11: `Chinese Tiktok `,
    msg12: ` Little Red Book（Red）`,
    msg13: `Y Talent Award`,
    msg14: `加入社区`,
  },
  ArtFestival: {
    msg1: `Y Talent Award`,
    msg2: `The ‘Y Talent Award’ is an international visual arts competition to celebrate the creativity of exceptional young artists between the age of 13 to 18`,
    msg3: `More Infomation`,
    msg4: `Ruthin Arts Festival`,
    msg5: `showcase the talents of young people from all around the world and curate expirmental artistic projects consist of three main sections: Contemporary Art, Film, and Poetry. It will encompass various forms such as performance, visual arts, installations, painting, sound, and recitation.
    From late June to mid-July 2024 (specific dates to be confirmed), the event will unfold in the enchanting venues of Ruthin School and various public spaces throughout the town of Ruthin. We will present a visual feast of artworks from 60+ artists that transcends global boundaries. Don't miss out on this exhilarating artistic journey; immerse yourself in the marvelous world of youthful creativity!`,
    msg6: `Yue MinJun ‘Y’ Art Centre`,
    msg7: `The Y Art Centre aims to support the artistic journey of young talents across the globe, through a rich tapestry of international, cultural and artistic exchange initiatives. Our vision for the Art Centre is to nurture artistic talents and encourage young people to express themselves through various art forms such as painting, writing, drama and music.
    `,
    msg8: `"Youth is our future. We hope to provide an international art platform to showcase the creativity and imagination of young artists from different countries and regions, and encourage young artists to communicate and develop through this platform." - Yue Minjun`,
  },
  International: {
    msg1: `Ruthin Education`,
    msg2: `International Short Courses and Camps`,
    msg3: `An Invitation to Explore and Excel`,
    msg4: `At Ruthin School, we're committed to providing an environment where passion and learning coalesce, creating extraordinary experiences. To achieve this, we are offering a series of exceptional International Summer School and Camp Programmes, designed for both British and International students aged 11-17. Our programmes are meticulously crafted by education experts to provide once in a lifetime, enriching experiences, opening doors to new opportunities and fostering unparalleled personal growth.`,
    msg5: `These programmes offer students the unique opportunity to stay and study in our beautiful campus and experience the academic excellence which defines Ruthin. Students will take part in a series of lessons and workshops, while also taking advantage of the beautiful landscape of North Wales.`,
    msg6: `Our Camps`,
    msg7: `International Academic Summer School`,
    msg8: `Art Camp`,
    msg9: `Football Camp`,
    msg10: `Creative Writing Camp`,
    msg11: `More Infomation...`,
    msg12: `What’s Included`,
    msg13: `Join Us This Summer!`,
    msg14: `Accommodation`,
    msg15: `All meals`,
    msg16: `Teaching and mentorships`,
    msg17: `Day trips and cultural activities`,
    msg18: `24/7 support`,
    msg19: `Reports and certificates`,
    msg20: `Access to Ruthin School facilities`,
    msg21: `Art supplies for Art Camp`,
    msg22: `Expand Your Horizons This Summer at Ruthin School`,
    msg23: `Contact for Camps`,
    msg24: `Email `,
    msg25: `Follow us`,
    msg26: `camps@ruthineducation.co.uk`,
    msg27: `Explore More...`,
  },
  RuthinSchool: {
    msg1: `Ruthin School`,
    msg2: `Introduction`,
    msg3: `Ruthin School is a special place because it is more than just a school. Here we encourage and guide our students to aim for the highest grades and have a great attitude to work but it is also a place where we look to the future and think about how our students can positively influence the world.`,
    msg4: `We are proud of our rich history, which gives us over 700 years of experience in nurturing the future generation. Established in 1284, we are one of the oldest schools in the United Kingdom. We are constantly evolving, embracing the best of our traditions and blending them with innovative, forward-thinking educational approaches.`,
    msg5: `Ruthin School: Nurturing the future generation since 1284`,
    msg6: `Ruthin Highlights`,
    msg7: `In 1984, Ruthin School was honored with a visit from Queen Elizabeth II, commemorating its 700th anniversary.`,
    msg8: `Rich History`,
    msg9: `At Ruthin School, the proportion of A* and A grades at the A-Level stage exceeds 60%, and over 90% of the graduates go on to prestigious universities such as Cambridge, Oxford, Imperial College London, London School of Economics, University College London, and the University of Warwick.`,
    msg10: `Outstanding Academic Results`,
    msg11: `The school offers GCSE and A-Level courses, striving for high-quality teaching and focusing on personal development to unlock students' potential. `,
    msg12: `Diverse Curriculum`,
    msg13: `As an international co-educational boarding school, Ruthin School aims to deliver diversified and globalized education.`,
    msg14: `International Education`,
    msg15: `Years of <br>History`,
    msg16: `G5 Admission from<br>Our Graduates`,
    msg17: `Curriculums<br>for GCSE + A-LEVEL`,
    msg18: `student support`,
    msg19: `Infinite Possibilities<br>for the Future`,
    msg20: ``,
  },
  TalentAward: {
    msg1: `Y Talent Award`,
    msg2: `The ‘Y Talent Award’ is an international visual arts competition to celebrate the creativity of exceptional young artists between the age of 13 to 18 from all around the world. The Y Talent Award is a collaboration between Y Art Centre, founded by the internationally acclaimed contemporary artist Yue Minjun, and Ruthin School, one of the oldest schools in the UK. The judging panel will select ten prize winners, including one grand prize. The judging panel consists of five professional visual artists, who are honorary members of the Y Art Centre. The ten winners will be invited to exhibit their work at Ruthin School, UK, from June to July 2024.`,
    msg3: `The judges are looking for a blend of creativity, technical prowess and emotional resonance. Like the works of Yue Minjun, they will seek artworks that demonstrate a unique and innovative approach to the medium, pushing the boundaries of traditional aesthetics and challenging the status quo. Originality and a distinctive artistic voice will be highly valued, as well as the ability to convey a compelling narrative or message through the visual language. Technical skill and craftsmanship will also play a significant role, with attention to detail, composition, and use of materials being key considerations. Furthermore, the judges will look for pieces that evoke genuine emotions, provoke thoughtful reflection, or spark a visceral response in the viewer.`,
    msg4: `Award Prizes`,
    msg5: `The Y Talent Award Grand Prize winner will receive £5,000 GBP in cash and a £5,000 GBP worth of 10-day Art Residency at the breathtaking resort and landscape of ‘Twelve Behind’ located in Guizhou, China. Twelve Behind is a dramatic landscape of waterfalls, mountains and boasts the longest cave system in Asia. It is famous for its stunning beauty and a place where artists gather from across the world to be inspired and create work.`,
    msg6: `Ten Y Talent nomination prize winners will receive a 10-day Art Residency in the beautiful resort and landscape of ‘Twelve Behind’, located in Guizhou, China.`,
    msg7: `Eligibility`,
    msg8: `Young talents from all around the world, aged between 13 to 18.`,
    msg9: `Artwork can be on any topic or theme.`,
    msg10: `All entries must be accompanied by a short description of no more than 100 words giving insight and context to the artwork.`,
    msg11: `All entries must be the original work of the applicant and should not be copies of existing pieces of art.`,
    msg12: `Submission `,
    msg13: `Free entry. `,
    msg14: `Open theme.`,
    msg15: `One artwork per artist.`,
    msg16: `All forms and media of visual arts are welcome. Dimensions - not limited.`,
    msg17: `Please complete the Y Talent Award 2024 application through our online system (opening on 11 March 2024), by Friday, 12 April 2024, 12 noon (GMT).`,
    msg18: `Shortlisted artists will be notified through Y talent Award website and email, by Friday 10 May 2024.`,
    msg19: `All shortlisted artworks are required to be delivered by post or in person to Ruthin School to be considered for the final prizes and the exhibition, by Friday 10 June, 12 noon. Delivery address: Ruthin School, Mold Road, Ruthin, Denbighshire, LL15 1EE, UK`,
    msg20: `Contact Us`,
    msg21: `For all further enquiries please email us at: `,
    msg22: `arts@ruthineducation.co.uk`,
    msg23: `Timeline`,
    msg36: ``,
    msg24: `Monday, 11 March 2024, 12 noon (GMT), submission entry open. Jury panel announcement.`,
    msg25: `Friday, 12 April 2024, 12 noon (GMT), submission deadline.`,
    msg26: `Friday, 10 May 2024, 12 noon (GMT), shortlisted artworks announced.`,
    msg27: `Friday, 28 June 2024  exhibition opening, winners of nomination and grand prizes announced. `,
    msg28: `July 2024 exhibition open to the public (Dates TBC).`,
    msg30: `Online application<br> open on`,
    msg31: `FINAL DEADLINE IS IN`,
    msg32: ` You are invited to participate in the Y Talent Award, an international visual arts competition to celebrate the creativity of exceptional young artists. `,
    msg33: `More<br> Information`,
    msg34: `Open Call for Visual Arts Submissions`,
    msg35: ` 11th March<br> 2024`,
    msg37: `Register Now`,
    msg38: `Log in`,
    msg39: `Don’t miss a chance,`,
    msg40: `submit`,
    msg41: `your work now`,
    msg42: ``,
    // msg38: `Log`,
    // msg38: `Log`,
    // msg38: `Log`,
  },
  home: {
    msg1: `About Our Group`,
    msg2: `At Ruthin Education, we are passionate`,
    msg3: `about providing world-class education`,
    msg4: `to students.`,
    msg5: `Learn More`,
    msg6: `Why Ruthin Education`,
    msg7: `Our School`,
    msg8: `This prestigious British boarding`,
    msg9: `school, with a rich history, forms`,
    msg10: `the cornerstone of our`,
    msg11: `educational offerings.`,
    msg12: `Learn More`,
    msg13: `Our Camps`,
    msg14: `Expand Your Horizons This`,
    msg15: `Summer at Ruthin School`,
    msg16: `View All`,
    msg17: `Ruthin <br>Art Education`,
    msg18: `Get Informed`,
    msg19: ``,
    msg20: `更多信息`,
    msg21: `更多信息`,
    msg22: `更多信息`,
    msg23: `更多信息`,
  },
  about: {
    msg1: `About`,
    msg2: `Our Group`,
    msg3: `At Ruthin Education, we are passionate about providing world-class education to students globally. Ruthin School, a distinguished British boarding school with 740 years of history, serves as the bedrock of our educational portfolio.

    ​
    
    <br>Beyond our core operation at Ruthin, we are constantly looking for opportunities to bring unique international learning experiences to our students, preparing them for success in a complex and highly interconnected world. We believe that this global perspective adds richness to their educational journey, providing them with the tools they need to navigate and excel in today's diverse society.`,
    msg4: `Mission`,
    msg5: `Our mission is to provide a transformative, globally-focused education, cultivating cross-cultural understanding. Through a diverse curriculum, we empower students with the skills needed to navigate an interconnected world. Ruthin Education Group strives to be a beacon of academic brilliance, shaping individuals who not only excel academically but also contribute meaningfully to a global society. In essence, our mission is to nurture well-rounded, culturally-aware leaders for the future.`,
    msg6: `Vision & Values`,
    msg7: `Respect`,
    msg8: `Responsibility`,
    msg9: `Integrity`,
    msg10: `Compassion`,
    msg11: `Courage`,
  },
  submit: {
    msg1: `My Account`,
    msg2: `How to sumbit`,
    msg3: `The deadline to submit artwork is Friday,12 Apri2024,12 noon(GMT). You can edit your submission until this deadline.`,
    msg4: `You can submit one artwork.`,
    msg5: `You’ll need one to four images of the artwork plus details including a title, medium and a short description.`,
    msg6: `You can save your submission as a draft and submit it at a later date if you need more time.`,
    msg7: `Questions? Don't hesitate to contact us`,
    msg8: `Summit Now`,
    msg9: `My Submissions`,
    msg10: `You have no submissions.`,
    msg11: `Sbumit Work`,
    msg12: `My Details`,
    msg13: `Name`,
    msg14: `School details`,
    msg15: `Update your details`,
    msg16: `Change password`,
    msg17: `Email`,
    msg18: `More information`,
    msg19: `Submission guidance`,
    msg20: `Call for entries poster `,
    msg21: `Terms and conditions `,
  },
  Registration: {
    msg1: `Registration`,
    msg2: `The ‘Y Talent Award 2024’`,
    msg3: `Open Call for Visual Arts Submissions`,
    msg4: `School Details`,
    msg5: `Please fill in the form below.`,
    msg6: `I am 13 years of age or older.`,
    msg7: `School Name`,
    msg8: `School Address`,
    msg9: `Street Address`,
    msg10: `Street Address 2`,
    msg11: `City`,
    msg12: `State / Regioin`,
    msg13: `Postal / Zip Code`,
    msg14: `Registration Form`,
    msg15: `Please fill in the form below.`,
    msg16: `Name`,
    msg17: `First`,
    msg18: `Last`,
    msg19: `Birthaday`,
    msg20: `Email`,
    msg21: `Phone`,
    msg22: `Address`,
    msg23: `Password`,
    msg24: `Confirm Password`,
    msg25: `Register for email updates about future opportunities and news and offers from Y Art Centre.`,
    msg26: `I agree to the Terms of Service below. 
    (By participating in this project, you need to accept our terms and conditions.)`,
    msg27: `Submit`,
    msg28: `Privacy policy`,
    msg29: `Country/Region`,
    msg: ``,
    msg: ``,
    msg: ``,
    msg: ``,
  },
  SubmitWork: {
    msg1: `Submission page`,
    msg2: `You are logged in as xxx Not you?`,
    msg3: `Log out`,
    msg4: `Artwork Information`,
    msg5: `Student Name`,
    msg6: `School year`,
    msg7: `Student age`,
    msg8: `Artwork title `,
    msg9: `(This will appear on the label of the artwork if your work is exhibited)`,
    msg10: `Description `,
    msg11: `(This should be a short statement about this artwork and what inspired you to make it. It should be no more than 100 words.)`,
    msg12: `Artwork type`,
    msg13: `Medium (optional)`,
    msg14: `You can add specific materials used to make this artwork here, such as ‘Acrylic paint on canvas’`,
    msg15: `Dimensions`,
    msg16: `Upload your artwork`,
    msg17: `Upload `,
    msg18: `Additional Image (You can upload 1-3 additional images  (such as different angles, and details) as supplementary images to better showcase your work)`,
    msg19: `Video`,
    msg20: `Add yout link`,
    msg21: `Your artwork can be a photograph taken with a phone (allow for up to 4 photos of the same artwork), tablet, or digital camera. It should be an accurate representation of the artwork and we’ll use this image online if it’s selected for the exhibition. `,
    msg22: `If you are submitting a video clip as your work, please include a link to the video clip in the description box. You can upload your work to Wetransfer/Youtube/Google Drive/One Drive or other cloud storage that permits public viewing. If you have trouble uploading it to a public domain, please contact us.`,
    msg23: `A few tips to get a good image:`,
    msg24: `- Make sure to get the whole artwork in the image, cropping after if needed`,
    msg25: `- Take the photo during the day if possible for better light`,
    msg26: `Back`,
    msg27: `Save as draft`,
    msg28: `Save and submit`,
    msg29: `Image  (Please upload one image of your work as the main display)`,
    msg30: `A4 portrait - 297 x 210 mm`,
    msg31: `A4 landscape - 210 x 297 mm`,
    msg32: `A3 portrait - 297 x 420 mm`,
    msg33: `A3 landscape - 420 x 297 mm`,
    msg34: `A2 portrait - 420 x 594 mm`,
    msg35: `Book `,
    msg36: `Drawing `,
    msg37: `Installation `,
    msg38: `Mixed Media `,
    msg39: `Model `,
    msg40: `Painting `,
    msg41: `Performance `,
    msg42: `Photograph `,
    msg43: `Print `,
    msg44: `Sculpture  `,
    msg45: `Sound Based  `,
    msg46: `Textile  `,
    msg47: `Video `,
    msg48: `Are you sure you want to submit? Once submitted, it cannot be modified. If you need to edit later, please save as draft first. `,
    msg49: `Confirm`,
    msg50: `Cancel`,
    msg51: `Tips`,
    msg52: `Image`,
    msg53: `information is incomplete`,
    msg54: `All submitted information and images can be modified, until 12 April 2024, 12 noon (GMT)`,
  },
  UploadDetails: {
    msg1: `Edit my name and school details`,
    msg2: `You are logged in as xxxxxx Not you?`,
    msg3: `Log out`,
    msg4: `Personal details`,
    msg5: `First Name`,
    msg6: `Last name`,
    msg7: `School details`,
    msg8: `School `,
    msg9: `Update details`,
    msg10: `Back`,
  },
  ChangePassword: {
    msg1: `Change password`,
    msg2: `You are logged in as xxxxxx`,
    msg3: `Not you? Log out`,
    msg4: `Email`,
    msg5: `Email address`,
    msg6: `Password`,
    msg7: `Current password`,
    msg8: `New password `,
    msg9: `(8 characters minimum)`,
    msg10: `New password confirmation`,
    msg11: `Update`,
    msg12: `Back`,
    msg13: `The two passwords are inconsistent`,
  },
  Submissionguidance: {
    msg1: `Guidance for`,
    msg2: `submissions`,
    msg3: `The ‘Y Talent Award’ is an international visual arts competition to celebrate the creativity of exceptional young artists between the age of 13 to 18 from all around the world.`,
    msg4: `Artwork can be on any topic or theme.`,
    msg5: `Visual artwork can consist of drawings, installations, mixed media, model, painting, performance, photography, print, sculpture, sound based, textile or video. `,
    msg6: `The first round of submissions will be digital submissions. If your work is shortlisted, we will contact you and arrange for the artwork to be delivered to Ruthin School, to be considered for our exhibition in July.`,
    msg7: `All entries must be accompanied by a short description of no more than 100 words giving insight and context to the artwork.`,
    msg8: `All entries must be the original work of the applicant and should not be copies of existing pieces of art or recreations.  `,
    msg9: `Art created by AI will not be accepted.`,
    msg10: `The judges are looking for a blend of creativity, technical prowess and emotional resonance. Like the works of Yue Minjun, they will seek artworks that demonstrate a unique and innovative approach to the medium, pushing the boundaries of traditional aesthetics and challenging the status quo. Originality and a distinctive artistic voice will be highly valued, as well as the ability to convey a compelling narrative or message through the visual language. Technical skill and craftsmanship will also play a significant role, with attention to detail, composition, and use of materials being key considerations. Furthermore, the judges will look for pieces that evoke genuine emotions, provoke thoughtful reflection, or spark a visceral response in the viewer.`,
    msg11: `A good image of the artwork is very important and could influence the way that the artwork is viewed by the judges. Read through our ‘How to photograph your artwork’ section below for some helpful tips and tricks. We only accept one artwork per applicant and will not accept late submissions.`,
    msg12: ` Teachers, if you intend to submit artwork for each student in your class, we advise against submitting works that are visually similar. We also advise you to begin uploading images of your submissions well ahead of the deadline to avoid last-minute or technical difficulties.`,
    msg13: `We enjoy seeing each and every artwork submitted and can’t wait to see what our young artists will make this year!`,
    msg14: `How to photograph your artwork`,
    msg15: `When you submit artwork to the Y Talent Award, we ask you to share an image of your work. It will be seen by the judges and be shown online if the artwork is selected for the exhibition, so it’s essential that you take a good photo that represents how the artwork looks in real life.`,
    msg16: `Here are some tips to help you capture a good image:`,
    msg17: `Use a neutral background so the attention is on the artwork. `,
    msg18: `Aim for a soft and even light, daylight is best to avoid shadows on the artwork.`,
    msg19: `Photograph your artwork unframed if you can as the glass or plastic can cause glare and reflections.`,
    msg20: `Flash can add glare, try not to use it if you have enough light.`,
    msg21: `Keep your camera steady, a flat surface or tripod can help.`,
    msg22: `A scanner works really well for smaller artworks, pencil drawings and artwork that is on crumpled or creased paper.`,
    msg23: `Make sure the whole artwork is in the photograph including all edges and any plinths or stands for sculpture. Crop any background out afterwards.`,
    msg24: `Take a few shots and use the best one for your submission.`,
    msg25: `For 3D works, you may upload up to 4 images to show your work from different angles.`,
    msg26: `Images must be either JPG or PNG format and no larger than 5MB.`,
    msg27: `If you are submitting a video clip as your work, please include a link to the video clip in the description box. You can upload your work to`,
    msg28: `Wetransfer/Youtube/Google Drive/One Drive or other cloud storage that permits public viewing. If you have trouble uploading it to a public domain, please contact us: `,
    msg29: `arts@ruthineducation.co.uk.`,
    msg30: ``,
    msg31: ``,
    msg32: ``,
    msg33: ``,
    msg34: ``,
  },
  terms: {
    msg1: `Y Talent Award 2024 - Terms and Conditions`,
    msg2: `Welcome to the Y Talent Award 2024, an international visual arts competition. Please read the following terms and conditions before submitting your entry:`,
    msg3: `1. Artists and their artwork:`,
    msg4: `1.1 The competition is open to artists worldwide aged 13 to 18.`,
    msg5: `1.2 One entry per artist.`,
    msg6: `1.3 Submissions must be completed by 12 April 2024 noon (GMT).`,
    msg7: `1.4 Artists warrant authorisation for all rights in the work.`,
    msg8: `1.5 All correspondence via email arts@ruthineducation.co.uk. `,
    msg9: `1.6 Shortlisted artists notified by 10 May 2024. Selected artists’ artworks to be delivered to Ruthin School by 10 June 2024. We will display selected artists’ work on Ruthin Education’s website for promotional purposes.`,
    msg10: `1.7 Exhibition at Ruthin School, UK in July 2024. Award Ceremony on 28 June 2024.`,
    msg11: `1.8 Exhibited works (except 1st prize) available for collection in Ruthin School in August 2024. Exact timing will be confirmed by email. There may be opportunities for your work to be exhibited at Ruthin Education’s partner galleries or other organisations, which shall be discussed and agreed by you and our partners.`,
    msg12: `1.9 You must be the owner of the entry and must confirm that the piece submitted is of your own origination. You must hold all moral and intellectual property rights for all the entries submitted.`,
    msg13: `1.10 Copyright remains with the artist, but Ruthin Education and Y Art Centre may reproduce exhibited works for promotional purposes.`,
    msg14: `1.11 Visitors allowed to photograph exhibited works for personal, non-commercial use.`,
    msg15: `1.12 Artist responsible for damage or loss from exhibition to final collection. Ruthin Education and/or their agents undertake no responsibility whatsoever for any loss of or any damage to artworks entrusted to them. Whether this is arising from fire, default, error, neglect, theft by carriers, packers or other persons and whether in their own employment or not. All goods are at the artist owner’s risk and Ruthin Education and/or their agents exclude all liability to the maximum extent permitted by law. You are recommended to insure your work at all times.`,
    msg16: `2. Delivery of shortlisted artworks:`,
    msg17: `2.1 Artists Arrange and pay for delivery to Ruthin School. `,
    msg18: `2.2 Postal entries in suitable packaging.`,
    msg19: `2.3 We are not responsible for wrapping or providing wrapping materials for returns.`,
    msg20: `2.4 Artists cover costs, including customs charges and duties, for courier-shipped works. Please check with courier company for customs clearance.`,
    msg21: `3. Collection/return of works:`,
    msg22: `3.1 Artists may collect their works in August 2024, to be arranged with Ruthin Education.`,
    msg23: `3.2 Unclaimed work will be disposed of after 31 August 2024.`,
    msg24: `4. Award:`,
    msg25: `4.1 One Y Talent Award Grand Prize: £5,000 cash, plus a 10-day Art Residency in Zunyi, Guizhou, China.`,
    msg26: `4.2 Ten nomination prizes: A 10-day Art Residency in Zunyi, Guizhou, Chin`,
    msg27: `4.3 The Residency: you shall be liable for any travel arrangements to and from the location of the residency. The residency needs to be organised by 31 December 2024. Ruthin Educaton shall cover all accommodation (five-star hotel) and meals for the Residency.`,
    msg28: `5. Parental Consent:`,
    msg29: `5.1 Artists under the age of 18 must have parental or legal guardian consent to participate in the competition.`,
    msg30: `5.2 By submitting an entry, parents or legal guardians are considered to have granted permission for their child's participation.`,
    msg31: `6.Modification of Terms and Conditions`,
    msg32: `6.1 Ruthin Education may amend the terms and conditions of the Art Prize (for both submitting the artworks and participating in the competition for shortlisted and winning artists) at any time by reasonable notice, including without limitation by posting revised terms on its website, which amended terms and conditions shall be binding upon you.`,
    msg33: `6.2 These terms and conditions shall be governed by English law. Therefore, any dispute shall be subject to the jurisdiction of the courts of England and Wales.`,
    msg34: `6.3 These rules are binding upon you from the moment you will submit your entry.`,
    msg35: `7. Contact:`,
    msg36: `For all further inquiries, please email us at `,
    msg37: `By submitting your entry, you acknowledge that you have read, understood, and agreed to abide by these terms and conditions. We look forward to showcasing your creativity at the Y Talent Award 2024!`,
    msg38: "arts@ruthineducation.co.uk",
  },
  login: {
    msg1: `Log in`,
    msg2: `Haven’t got an account yet?`,
    msg3: ` Create one`,
    msg4: `Email `,
    msg5: `Password`,
    msg6: `Remember me`,
    msg7: `Forget your password?`,
  },
  forgot: {
    msg1: `Forget your password`,
    msg2: `Email?`,
    msg3: ` Sent me reset instructions`,
    msg4: ` Change successful`,
    msg5: ` show password`,
    msg6: ` The email has been sent to your email address`,
    msg7: ` Read more`,
    msg8: ` Read less`,
    msg9: ` Upload successful.`,
    msg10: ` Submission successful.`,
    msg11: `（The Image file must be JPEG or PNG format and no larger than 5MB.)`,
    msg12: `The user does not exist or the password is incorrect`,
    msg13: `This mailbox was not found`,
    msg14: `Old password error`,
    msg15: `The password must contain at least 6 characters`,
    msg16: `Length`,
    msg17: `Width`,
    msg18: `Height`,
    msg19: `Submission successful. The progress and results of the competition will be updated here.`,
    msg20: `Under review`,
    msg21: `Competition Result: We regret to inform you that your artwork did not qualify.`,
    msg22: `Competition Result: Congratulations! Your artwork has been selected.`,
    msg24: `The email address is registered`,
  },
};
