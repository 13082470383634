import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/eczar/font.css";
import animated from "animate.css";
import HeadMenu from "./components/HeadMenu/index";
// import VueI18n from "vue-i18n"; //引入对应的组件
import VueI18n from "vue-i18n";
Vue.component("head_Menu", HeadMenu); /*初始化公共组件*/
import Footpage from "./components/Footpage/index";
import phoneSelect from "./components/phoneSelect/index";
import Pagination from "@/components/Pagination";
import i18n from "./lang";
//页面加载后滑至顶部
router.afterEach(() => {
  //NProgress.done();
  window.scrollTo(0, 0);
});
Vue.component("Footpage", Footpage); /*初始化公共组件*/
Vue.component("phoneSelect", phoneSelect); /*初始化公共组件*/
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.use(ElementUI);
Vue.use(VueI18n); //挂载使用i18n的插件
Vue.component("Pagination", Pagination);
// 引入动画
Vue.use(animated);
new Vue({
  router,
  i18n,
  components: { HeadMenu, Footpage, phoneSelect } /*添加自定义组件*/,
  render: (h) => h(App),
}).$mount("#app");
