<template>
    <div class="top">
        <div class="infodiv">
            <div class="info">
                <div class="info1 infov">
                    <h2 class="titleh2">{{ $t('footpage.msg1') }}</h2>
                    <p class="infop1">Mold Rd, Ruthin LL15 1EE, UK</p>
                    <p class="infop1"><a href="https://groups.google.com/a/ruthineducation.co.uk/g/info" target="_blank"
                            rel="noopener noreferrer">info@ruthineducation.co.uk</a> </p>
                    <p class="infop2"><a
                            href="https://ruthinschoolcouk-my.sharepoint.com/:w:/g/personal/luy_ruthinschool_co_uk/Efakw-KotFFAqoBdBTvJ59kBiJKwbNYSAtwV7VNPhzoj8A?rtime=qdw8w4Ik3Eg"
                            target="_blank" rel="noopener noreferrer">{{ $t('footpage.msg2') }}</a></p>
                </div>
                <div class="info1">
                    <h2 class="titleh2">{{ $t('footpage.msg8') }}</h2>
                    <p class="infop2 wechatimgdiv" @mouseenter="onMouseOverer" @mouseleave="onMouseOuter">
                        <img src="@/assets/svg/wechat.svg" alt="">
                        {{ $t('footpage.msg3') }}
                        <img v-if="ershow" class="wechatimg" src="../../assets/static/60.png" alt="">
                    </p>
                    <p>
                        <img class="wechatimg1" src="../../assets/static/60.png" alt="">
                    </p>
                    <p class="infop2"><img src="@/assets/svg/Twitter.svg" alt=""><a href="https://twitter.com/RuthinSchool"
                            target="_blank" rel="noopener noreferrer">{{ $t('footpage.msg4') }}</a></p>
                    <p class="infop2"><img src="@/assets/svg/Linkedin.svg" alt=""><a
                            href="https://www.linkedin.com/company/ruthinschool/" target="_blank"
                            rel="noopener noreferrer">{{ $t('footpage.msg5') }}</a></p>
                    <p class="infop2"><img class="Instagram" src="@/assets/static/Instagram.png" alt=""><a
                            href="https://www.instagram.com/ruthinschoolofficial/" target="_blank"
                            rel="noopener noreferrer">{{ $t('footpage.msg6') }}</a></p>
                </div>
                <div class="info1 footother" v-if="foot == 1">
                    <h2 class="titleh2">{{ $t('footpage.msg9') }}</h2>
                    <p @mouseenter="onMouseOver(1)" @mouseleave="onMouseOut" class="infop2 wechatimgdiv">
                        <img src="@/assets/svg/wechat.svg" alt="">
                        {{ $t('footpage.msg13') }}
                    </p>
                    <p @mouseenter="onMouseOver(2)" @mouseleave="onMouseOut" class="infop2"><img
                            src="../../assets/static/71.png" alt="">{{ $t('footpage.msg11') }}</p>
                    <p @mouseenter="onMouseOver(3)" @mouseleave="onMouseOut" class="infop2"><img class="redbook"
                            src="../../assets/static/72.png" alt="">{{ $t('footpage.msg12')
                            }}</p>
                    <p class="infop2"><img class="Instagram" src="@/assets/static/Instagram.png" alt=""><a
                            href="https://www.instagram.com/ruthinglobal" target="_blank" rel="noopener noreferrer">{{
                                $t('footpage.msg6') }}</a></p>
                    <p @mouseenter="onMouseOver(4)" @mouseleave="onMouseOut" class="infop2">
                        <img src="@/assets/svg/wechat.svg" alt="">{{ $t('footpage.msg10')
                    }}</p>
                    <img v-if="showimg" class="otherimg" :src="imgurl" alt="">
                    <img v-if="showimg" class="mobleotherimg" :src="imgurl" alt="">
                </div>
            </div>

        </div>
    </div>
</template>
  
<script>
export default {
    name: 'Footpage',
    props: ['foot'],
    data() {
        return {
            msg: '头部',
            showimg: false,
            imgurl: '',
            ershow:false
        };
    },
    methods: {
        onMouseOverer(){
            this.ershow = true;
        },
        onMouseOuter(){
            this.ershow = false;
        },
        onMouseOver(n) {
            console.log("鼠标已经移入");
            this.showimg = true
            if (n == 1) {
                this.imgurl = require('@/assets/static/73.jpg')
            } else if (n == 2) {
                this.imgurl = require('@/assets/static/77.jpg')
            } else if (n == 3) {
                this.imgurl = require('@/assets/static/76.png')
            } else {
                this.imgurl = require('@/assets/static/74.jpg')
            }
            // ../../assets/static/60.png
        },
        onMouseOut() {
            console.log("鼠标已经移出");
            this.showimg = false
        }
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footother {
    position: relative;
}

.glodp {
    box-sizing: border-box;
    padding-left: 38px;
}

.wechatimgdiv {
    position: relative;
}

.wechatimg {
    display: block;
    position: absolute;
    width: 200px !important;
    height: 200px !important;
    left: 170px;
    top: -15px;
}

.otherimg {
    /* display: block; */
    position: absolute;
    width: 200px !important;
    height: 200px !important;
    right: 0px;
    top: 50px;
    object-fit: cover;
}

.mobleotherimg {
    display: none;
}

.wechatimg1 {
    display: none;
}

.infodiv {
    width: 100%;
    margin: 0 auto;
    background: #fff;
    box-sizing: border-box;
    padding: 20px 0;
}

.info {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.titleh2 {
    color: #000;
    margin-bottom: 40px;
}

.info1 {
    width: 33%;
    text-align: left;
    box-sizing: border-box;
    /* padding-left: 13%; */
}

.info2 {
    width: 50%;
    text-align: left;
    box-sizing: border-box;
    /* padding-left: 13%; */
}

.infop1 {
    font-size: 17px;
    color: rgb(129, 150, 153);
    line-height: 32px;
    cursor: pointer;
}

.infop2 {
    line-height: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 17px;
}

.iptdiv {
    color: rgb(129, 150, 153);
    display: flex;
    border-bottom: 1px solid rgb(129, 150, 153);
}

.iptdiv1 {
    margin-top: 20px;
    color: rgb(129, 150, 153);
    border-bottom: 1px solid rgb(129, 150, 153);
}

.iptdiv div {
    width: 50%;
}

.iptdiv input {
    width: 50%;
    height: 40px;
    line-height: 40px;
    outline: none;
    border: none;
}

.iptdiv1 input {
    width: 100%;
    height: 40px;
    line-height: 40px;
    outline: none;
    border: none;
}

.Submit {
    line-height: 50px;
    font-size: 18px;
    font-weight: bold;
    color: rgb(129, 150, 153);
    cursor: pointer;
}

.infop2 img {
    width: 25px;
    height: 25px;
    margin: 0 7px;
}

.Instagram {
    width: 39px !important;
    height: 39px !important;
    margin: 0 !important;
}

.infov {
    /* padding-left: 13%; */
}

/* 手机屏幕的字体大小 */
@media screen and (max-width: 900px) {

    .info1,
    .info2 {
        width: 100%;
    }

    .wechatimg {
        display: none;
    }

    .wechatimg1 {
        display: block;
        width: 200px;
        height: 200px;
    }

    .otherimg {
        display: none;
    }

    .mobleotherimg {
        display: block;
        width: 200px !important;
        height: 200px !important;
        right: 0px;
        top: 50px;
        object-fit: cover;
    }
}
</style>
  